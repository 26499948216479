<template>
	<div class="content">
		<div class="tabs-div">
			<div class="tabs b-w flex f-ai-c" >
				<!-- :style="{width: tabs.length * 75 + 'px'}" -->
				<div
					v-for="(item, index) in tabs"
					:key="index"
					class="tabs-item"
					:class="index === activeTab ? 'active' : ''"
					@click="tabClick(index)"
				>
					{{item.name}}{{[1, 2].indexOf(index) > -1 ? `(${item.count})` : ''}}
				</div>
			</div>
		</div>
		<div v-if="!dataList.length" class="no-data flex f-jc-c f-ai-c">
			<div class="flex f-d-c f-ai-c">
				<img src="../../assets/images/no-data.png" alt="">
				<span>暂无数据</span>
			</div>
		</div>
		<div v-else class="wrap">
			<div v-for="(item, index) in dataList" :key="index" class="b-w order-item" @click="toDetail(item.id)">
				<div class="item-top flex f-ai-c">
					<i></i>
					<span class="title">预约单</span>
					<span class="status">
						{{
							item.status === 1 ? '待支付' :
							item.status === 2 ? '待收货' :
							item.status === 3 ? '已完成' :
							item.status === 4 ? '已取消' :
							item.status === 5 ? '待发货' :
							item.status === 6 ? '已退款' :
							'未知状态'
						}}
					</span>
				</div>
				<div class="item-middle flex">
					<span>{{item.md}}</span>
					<span>共{{item.jiliang || 0}}剂，每日{{item.jlPerDay || 0}}剂，1剂分{{item.times || 0}}次服用</span>
				</div>
				<div class="item-bottom flex f-jc-sb f-ai-c">
					<span>￥ {{item.integer}}.<i>{{item.decimal}}</i></span>
					<div class="btns">
						<div class="btn cancel" v-if="item.status === 1" @click.stop="cancelHandle(item.id)"><span>取消</span></div>
						<div class="btn full" v-if="item.status === 1" @click.stop="toPay(item.id)"><span>去支付</span></div>
						<div class="btn full sure" v-if="item.status === 2" @click.stop="receiptHandle(item.id)"><span>确认收货</span></div>
						<div class="btn full sure" v-if="item.status > 2" @click.stop="toScan(item.id)"><span>查看</span></div>
					</div>
				</div>
			</div>
		</div>
		<van-popup v-model="showCancelOrder" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>是否确认取消该订单？</span>
			<div class="back-btns">
				<span @click="closeCancelDialog(1)">确定</span>
				<span @click="closeCancelDialog()">我再想想</span>
			</div>
		</van-popup>
		<van-popup v-model="showReceiptOrder" class="flex f-ai-c back-dialog">
			<h1>提示</h1>
			<span>确认收到货物？</span>
			<div class="back-btns">
				<span @click="closeReceiptDialog()">我再想想</span>
				<span @click="closeReceiptDialog(1)">确定</span>
			</div>
		</van-popup>
	</div>
</template>
<script>
import { yydList, appointmentCancel, receiptOrder } from '@/request/api/user';
import { ceil } from '@/utils';
export default {
	name: 'orderIndex',
	data () {
		return {
			activeTab: 0,
			selOrderId: '',
			showCancelOrder: false,
			showReceiptOrder: false,
			tabs: [
				{ name: '全部', count: 0 },
				{ name: '待支付', count: 0 },
				{ name: '待收货', count: 0 },
				{ name: '已完成', count: 0 },
				{ name: '已取消', count: 0 },
				{ name: '待发货', count: 0 },
				{ name: '已退款', count: 0 }
			],
			dataList: []
		};
	},
	created () {
		this.getYydList();
	},
	mounted () {
		// if (window.history && window.history.pushState) {
		// history.pushState(null, null, document.URL);
		// window.addEventListener('popstate', this.onBack, false);
		// }
		window.addEventListener('popstate', this.onBack, false);
	},
	destroyed () {
		window.removeEventListener('popstate', this.onBack, false);
	},
	methods: {
		onBack () {
			this.$root.go('/user-center');
		},
		tabClick (tab) {
			this.activeTab = tab;
			this.getYydList();
		},
		getYydList () {
			this.dataList = [];
			yydList({
				status: this.activeTab
			}).then(data => {
				if (data && data === 'retry') {
					this.getYydList();
				} else if (data) {
					// console.log('data==========', data);
					for (let i = 0; i < data.yyds.length; i++) {
						data.yyds[i].md = [];
						if (!data.yyds[i].rp) data.yyds[i].rp = []
						data.yyds[i].rp.forEach(r => {
							let name = `${r.name}`;
							if (data.yyds[i].status === 2 || data.yyds[i].status === 3 || data.yyds[i].status === 5 || (data.yyds[i].status === 1 && data.yyds[i].payFirst === 0)) {
								name += `${r.count}${r.unit}`;
							}
							data.yyds[i].md.push(`${name}`); // .split('/')[1]
						});
						data.yyds[i].md = data.yyds[i].md.join('、');
						if (!data.yyds[i].totalFee) data.yyds[i].totalFee = ''
						data.yyds[i].totalFee = ceil(+data.yyds[i].totalFee, 2)
						data.yyds[i].integer = data.yyds[i].totalFee.toString().split('.')[0];
						data.yyds[i].decimal = data.yyds[i].totalFee.toString().split('.')[1] || '00';
					}
					this.dataList = data.yyds;
					this.tabs[1].count = data.waitingPayCount;
					this.tabs[2].count = data.waitingGetCount;
				}
			}).catch(e => {
				console.log('e=====', e)
				this.$root.elMsg(e.message, 'fail');
			});
		},
		toPay (id) {
			this.$root.go('/appointment-detail', { id });
		},
		closeCancelDialog (type) {
			if (type) {
				appointmentCancel({
					id: this.selOrderId
				}).then(data => {
					if (data && data === 'retry') {
						this.closeCancelDialog();
					} else if (data && data.ok === 1) {
						this.getYydList();
					}
				});
			}
			this.showCancelOrder = false;
		},
		cancelHandle (id) {
			this.selOrderId = id;
			this.showCancelOrder = true;
		},
		receiptHandle (id) {
			this.selOrderId = id;
			this.showReceiptOrder = true;
		},
		closeReceiptDialog (type) {
			if (type) {
				receiptOrder({
					appointmentId: this.selOrderId
				}).then(data => {
					if (data && data === 'retry') {
						this.closeReceiptDialog();
					} else if (data && data.ok === 1) {
						this.getYydList();
					}
				});
			}
			this.showReceiptOrder = false;
		},
		toScan (id) {
			this.$root.go('/appointment-detail', { id });
		},
		toDetail (id) {
			this.$root.go('/appointment-detail', { id });
		}
	}
};
</script>
<style lang="less" scoped>
	.content {
		// padding-top: 46px;
		display: flex;
		flex-direction: column;
		overflow: hidden;
		height: 100%;
	}
	.tabs-div {
		overflow-x: auto;
		overflow-y: hidden;
		width: 100%;
		height: 46px;
		// display: inline-block;
	}
	.tabs {
		// height: 46px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #7D7D7D;
		padding: 0;
		// position: fixed;
    // top: 0;
		// z-index: 1;
		// display: flex;
		align-items: center;
		// overflow-x: scroll;
		// overflow-y: hidden;
		// float: left;
		height: 100%;
		width: auto;
		display: inline-flex;
		// width: 100%;
		// justify-content: space-around;
		div.tabs-item {
			position: relative;
			height: 100%;
			line-height: 46px;
			float: left;
			// flex: 1;
			text-align: center;
			white-space: nowrap;
			// margin: 0 5px;
			width: 75px;
			&.active {
				color: #FF6450 !important;
				&::after {
					content: '';
					width: 30px;
					height: 2px;
					background: #FF6450;
					border-radius: 2.5px;
					position: absolute;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%);
				}
			}
		}
	}
	.no-data {
		height: 300px;
		img {
			width: 100px;
			height: 100px;
			margin: 0 auto;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #8B8B8B;
		}
	}
	.wrap {
		flex: 1;
		display: flex;
    flex-direction: column;
    overflow: auto;
		margin: 0;
		padding: 0 10px 10px 10px;
	}
	.order-item {
		padding: 0 15px;
		padding-bottom: 10px;
		margin-top: 12px;
		// height: 161px;
		box-shadow: 0px 4.5px 18px -4px rgba(16, 31, 46, 0.04);
		border-radius: 4px;
	}
	.item-top {
		height: 39px;
		box-sizing: border-box;
		position: relative;
		border-bottom: 1px solid #F3F3F3;
		i {
			display: inline-block;
			background: url('../../assets/images/user-2.png') no-repeat;
			background-size: contain;
			width: 15px;
			height: 15px;
			margin-right: 5px;
		}
		.title {
			font-size: 13px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #7D7D7D;
		}
		.status {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #F33D3A;
			position: absolute;
			right: 15px;
		}
	}
	.item-middle {
		// height: 71px;
		padding: 15px 0;
		border-bottom: 1px solid #F3F3F3;
		flex-direction: column;
		span:nth-child(1) {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3B3D40;
			margin-bottom: 15px;
		}
		span:nth-child(2) {
			font-size: 12px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #7D7D7D;
		}
	}
	.item-bottom {
		margin-top: 10px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		span {
			font-size: 15px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #F33D3A;
		}
		i {
			font-size: 11px !important;
		}
		.btns {
			display: flex;
			align-items: center;
		}
		.btn {
			// width: 80px;
			// height: 30px;
			border-radius: 16.5px;
			border: 1px solid #FF6450;
			color: #FF6450;
			font-weight: 400;
			font-size: 13px;
			display: flex;
			align-items: center;
			justify-content: center;
			line-height: 13px;
			span {
				color: #FF6450;
			}
		}
		.cancel {
			background: #FFFFFF;
			margin-right: 10px;
			padding: 7px 24px;
		}
		.full {
			span {
				color: white;
			}
			border: none;
			padding: 8px 18px;
			background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
		}
		.sure {
			padding: 8px 12px;
		}
	}
	.back-dialog {
		flex-direction: column;
		padding: 30px 25px 25px 25px;
		width: 290px;
		height: 120px;
		background: #FFFFFF;
		border-radius: 7px;
		justify-content: space-between;
		h1 {
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #232830;
		}
		span {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #3B3D40;
			text-align: center;
		}
		.back-btns {
			span {
				display: inline-block;
				width: 112px;
				height: 33px;
				background: #FFFFFF;
				border-radius: 16.5px;
				border: 1px solid #FF6450;
				line-height: 33px;
				margin-top: 20px;
				&:nth-child(1) {
					color: #FF6450;
					margin-right: 16px;
				}
				&:nth-child(2) {
					color: #FFFFFF;
					background:  linear-gradient(136deg, #FF7F4E 0%, #FF6453 100%);
				}
			}
		}
	}
</style>